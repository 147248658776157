import { ISvgImport } from '@interfaces/svg';
import { twMerge } from 'tailwind-merge';

interface IProps {
  Icon: ISvgImport;
  title: string;
  onChange: (tabId: number) => void;
  id: number;
  activeTabId?: number;
}

const ProductsFilterTab = ({
  onChange,
  Icon,
  title,
  id,
  activeTabId,
}: IProps) => {
  return (
    <button
      disabled={id === activeTabId}
      onClick={() => onChange(id)}
      className={twMerge(
        'flexCenter group relative h-20 w-full flex-col border-r border-neutral700 pb-5 text-neutral200 transition-all duration-300 ease-out hover:bg-neutral800 hover:text-white max-md:border-b max-md:odd:border-r-0 sm:h-28 md:h-40',
        'first:max-md:col-span-2',
        activeTabId === id && 'pointer-events-none bg-neutral800 text-white'
      )}
    >
      <Icon
        className={twMerge(
          'size-12 fill-neutral700 text-neutral700 group-hover:text-white group-hover:*:fill-white',
          'scale-50 sm:scale-75 md:scale-100',
          activeTabId === id &&
            '*:fill-brandPrimary group-hover:*:fill-brandPrimary'
        )}
      />
      <p className="md:label-large label-medium absolute bottom-2 w-[90%] overflow-hidden text-ellipsis md:bottom-5">
        {title}
      </p>
    </button>
  );
};
export default ProductsFilterTab;
