import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { Flipped } from 'react-flip-toolkit';

import { UserProductListResponseData } from '@interfaces/marketplace/products';
import { useQueryClient } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';
import useBookmarkProductMutation from '@api/user/product/mutations/useBookmarkProductMutation';
import useUnBookmarkProductMutation from '@api/user/product/mutations/useUnBookmarkProductMutation';

import Ellipsis from '@components/Ellipsis';
import CompanyAvatar from '@components/companyAvatar';
import PriceWrapper from '@components/priceWrapper';
import RatingBadge from '@components/ratingBadge';

import { formatBigNumber } from '@features/forum/utils';

import { PRODUCT_LIST_KEY } from '@shared/constants/queryKeys';
import { toastWarning } from '@shared/utils';

import BookmarkButton from './BookmarkButton';
import ProductCardDescription from './ProductCardDescription';
import ProductCardTag from './ProductCardTag';

interface IProps {
  product: UserProductListResponseData;
  onBookmarkChange?: () => void;
}

const ProductCard = ({
  product,
  onBookmarkChange: onBookmarkChange,
}: IProps) => {
  const CARD_LINK = `/products/${product.id}`;
  const { data } = useCurrentUser();
  const queryClient = useQueryClient();

  const {
    id,
    categories,
    name,
    isBookmark,
    thumbnail,
    publisher,
    numVisitor,
    price,
    shortDescription,
    avgRating,
    isChargeByToken,
  } = product;

  const [isBookmarked, setIsBookmarked] = useState(isBookmark);

  const { mutateAsync: bookmarkMutation, isPending: isBookmarkPending } =
    useBookmarkProductMutation();
  const { mutateAsync: unBookmarkMutation, isPending: isUnBookmarkPending } =
    useUnBookmarkProductMutation();

  const handleBookmarkClick = async () => {
    if (!data?.data) {
      toastWarning('You need to login to perform this action');
      return;
    }

    if (isBookmarkPending || isUnBookmarkPending) return;

    setIsBookmarked((prev) => !prev);

    if (!isBookmarked) await bookmarkMutation(id);
    else await unBookmarkMutation(id);

    // Used to refetch the bookmarks on my profile, if cached
    queryClient.invalidateQueries({
      queryKey: PRODUCT_LIST_KEY(),
    });

    onBookmarkChange?.();
  };

  const sortedCategories = categories.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Flipped flipId={id}>
      <div
        className={twMerge(
          'roundedBrTl transitionColor group relative z-0 h-[428px] border border-neutral700 will-change-transform hover:border-neutral300 md:h-[492px]'
          // 'hover:bg-product-card-gradient' ,// TODO: Nac rjesenje kako da radi s novim bojama
        )}
      >
        {/* Overlay for going to card page */}
        <Link href={CARD_LINK} className="absolute z-10 size-full" />

        <div className="productCardImageGradient relative h-44 w-full">
          {thumbnail && (
            <Image
              src={thumbnail}
              alt={name}
              unoptimized
              quality={10}
              fill
              className="rounded-tl-lg object-cover"
            />
          )}
          <RatingBadge rating={avgRating} className="absolute left-2 top-2" />
          <BookmarkButton
            isBookmarked={!!isBookmarked}
            onClick={handleBookmarkClick}
          />
        </div>
        <div className="flex h-[250px] min-h-[186px] flex-col rounded-br-lg px-6 pb-6 md:h-[314px]">
          <div className="relative bottom-4 flex flex-col gap-3">
            <CompanyAvatar user={publisher.user} />
            <Link href={CARD_LINK}>
              <Ellipsis
                title={name}
                className="heading-xxsmall relative z-99"
                hideIcon
              />
            </Link>
            <div className="flex flex-wrap gap-3">
              {sortedCategories.map((category, i) => (
                <ProductCardTag key={i} tag={category.name} />
              ))}
            </div>
          </div>
          <ProductCardDescription description={shortDescription || ''} />
          <div className="mt-auto">
            <div className="mb-4 h-px w-full bg-neutral700" />

            <div className="flex items-center justify-between">
              <div>
                <PriceWrapper price={price ?? 0} />
                {isChargeByToken && (
                  <p className="border-light700 absolute bottom-2 ml-6 mt-2 w-fit border border-yellow600 px-1 text-[9px] font-bold leading-3 text-yellow600">
                    Token base
                  </p>
                )}
              </div>

              <p className="font-xsmall font-bold tracking-[0.44px] text-neutral500">{`${formatBigNumber(numVisitor)} VISITORS`}</p>
            </div>
          </div>
        </div>
      </div>
    </Flipped>
  );
};

export default ProductCard;
